/******* screen-small.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* fontface.less 2013-1-16 *******/
/* open-sans-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('/extras/fonts/open-sans-v40-latin-500.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/extras/fonts/open-sans-v40-latin-600.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/extras/fonts/open-sans-v40-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}
.unit > .body,
.unit > form > .body {
  margin: 0.4em 0;
  width: 100%;
}
.cb-part-body {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}
.lead {
  margin-bottom: 0 !important;
}
.list {
  margin-top: 0.4em !important;
  margin-bottom: 0.4em !important;
}
.gist,
.dent {
  display: list-item;
  list-style-position: outside;
}
.gist {
  margin-left: 16px;
}
.dent {
  margin-left: 32px;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #4d4d4d;
  color: #4d4d4d;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
  margin-left: 14px;
}
.link,
.load {
  background-repeat: no-repeat !important;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #000 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
.grid table {
  border-collapse: collapse;
}
.cb-table-layout-fixed {
  table-layout: fixed;
}
.grid td,
.grid th {
  border-width: 0;
  border-style: solid;
  border-color: #eaeaeb;
  vertical-align: top;
}
.horz td,
.horz th {
  padding: 1px;
}
.vert td,
.vert th {
  padding: 1px 4px;
}
.horz .trim td,
.horz .trim th {
  border-width: 1px 0;
}
.vert .trim td,
.vert .trim th {
  border-width: 0 1px;
}
.grid th {
  font-weight: bold;
  background: #ccc;
  text-align: left;
}
.vert > .beam td.odd,
.horz > .beam > .odd {
  background: #e6e6e6;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #404040;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #404040;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset {
  padding: 0.2em 0 0.5em;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: bold;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding-bottom: 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  margin-right: 15px;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #404040;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
#disp.srch a:visited {
  color: #40abb0;
}
.srch .text {
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #bfbfbf;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding-bottom: 0.8em;
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #4bb9be;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #4bb9be;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@media screen and (max-width: 765px) {
  .cb-table-3up.vert {
    position: relative;
    display: block;
    border: 1px solid #eaeaeb;
  }
  .cb-table-3up.vert td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-3up.vert > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-3up.vert > tbody tr {
    display: inline-block;
    border-left: 1px solid #eaeaeb;
  }
  .cb-table-3up.vert > tbody tr:first-child {
    border: 0;
  }
  .cb-table-head .cb-table-3up.vert > thead {
    display: block;
    float: left;
  }
  .cb-table-head .cb-table-3up.vert > thead > tr {
    display: block;
  }
  .cb-table-head .cb-table-3up.vert > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot {
    display: block;
    float: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot > tr {
    display: block;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-both .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 1px solid #eaeaeb;
  }
  .cb-table-both .cb-table-3up.horz td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-both .cb-table-3up.horz > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr {
    display: inline-block;
    border-left: 1px solid #eaeaeb;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr:first-child {
    border: 0;
  }
  .cb-table-both .cb-table-3up.horz > thead {
    display: block;
    float: left;
  }
  .cb-table-both .cb-table-3up.horz > thead > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot {
    display: block;
    float: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-head .vert tr.odd {
    background: #e6e6e6;
  }
  .cb-table-head .vert td {
    background: none !important;
  }
  .cb-table-column-inactive {
    position: relative;
  }
  .cb-table-column-inactive th {
    max-width: 1em;
    overflow: hidden;
    white-space: nowrap;
  }
  .cb-table-column-inactive > tr:after {
    top: 0;
    position: absolute;
    left: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    content: '…';
    display: inline-block;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tbody,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tbody,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    display: block;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead tr,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot tr {
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th {
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    border: 1px solid #eaeaeb;
    margin-bottom: 4px;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    position: relative;
    padding: 1px 4px;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    font-weight: bold;
    position: absolute;
    top: 0;
    overflow: hidden;
    padding: 1px 4px;
    width: 35%;
    border: 0;
    content: attr(data-title);
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *behavior: url(/objects/boxsizing/boxsizing.htc);
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td {
    padding-left: 38%;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after {
    left: 0;
    padding-right: 1%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    padding-right: 38%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    right: 0;
    padding-left: 1%;
  }
  .cb-table-none .cb-table-3up.horz {
    display: block;
    border: 0;
  }
  .cb-table-none .cb-table-3up.horz tbody,
  .cb-table-none .cb-table-3up.horz th,
  .cb-table-none .cb-table-3up.horz tr,
  .cb-table-none .cb-table-3up.horz td {
    display: block;
  }
  .cb-table-none .cb-table-3up.horz tr {
    border: 1px solid #eaeaeb;
    margin-bottom: 4px;
  }
  .cb-table-none .cb-table-3up.horz td {
    padding: 1px 4px;
    border: 0;
  }
  .cb-table-columns-3 tr {
    min-width: 50%;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
/******* site.less 2013-1-16 *******/
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(75, 185, 190, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 17px;
  font-size: 1.7rem;
  width: 100%;
  background-color: transparent;
  color: #000;
  font-family: 'Open Sans', helvetica, sans-serif;
  line-height: 1.2;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  width: 1300px;
  max-width: 100%;
  position: relative;
  left: 0;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.desk.mood {
  width: 1300px;
  max-width: 100%;
}
body.cb-toggle-target-active .desk {
  left: 230px;
  -o-transition: all 0.218s ease-out;
  -moz-transition: all 0.218s ease-out;
  -webkit-transition: all 0.218s ease-out;
  transition: all 0.218s ease-out;
}
#root .desk.cb-toggle-clicked {
  left: 0;
}
#home {
  float: right;
  width: 585px;
  margin-top: 40px;
  margin-bottom: 20px;
}
.mobile-home {
  float: right;
  width: 216px;
  margin: 12px 8% 13px 0;
}
.logo {
  width: 100%;
  height: auto;
}
#head {
  float: left;
  width: 100%;
  margin-top: 50px;
  position: relative;
  z-index: 2;
}
#wrapper {
  float: left;
  width: 100%;
  overflow: hidden;
}
#header {
  float: left;
  width: 100%;
}
.service_custcontact {
  position: absolute;
  left: 0;
  top: 38px;
  text-transform: uppercase;
}
#navigation {
  float: left;
  width: 100%;
  background: #007b8d url(/images/navigation.png) no-repeat 50% 0;
}
#content {
  float: left;
  width: 100%;
}
#maincontent {
  float: left;
  width: 100%;
}
#footer {
  float: left;
  width: 100%;
}
#multimood {
  float: left;
  width: 100%;
}
h1 {
  font-size: 32px;
  text-transform: uppercase;
  color: #4bb9be;
  font-weight: 600;
}
.cb-layout2 h1 {
  color: #fff;
}
h2 {
  font-size: 32px;
  text-transform: uppercase;
  color: #4bb9be;
  font-weight: 600;
}
div.fold h2 {
  color: #000;
  font-size: 17px;
  padding: 4px 0;
  text-transform: none;
  border-top: 1px solid #eaeaeb;
  border-bottom: 1px solid #eaeaeb;
}
div.grid h2 {
  text-transform: none;
  color: #000;
  font-size: 17px;
  font-weight: bold;
}
h3 {
  border-top: 1px solid #eaeaeb;
  border-bottom: 1px solid #eaeaeb;
  font-weight: 600;
  padding: 4px 0;
}
strong {
  font-weight: bold;
}
.loud {
  padding: 0.2em 0.4em;
  background-color: #bfbfbf;
  color: #fff;
  /* redo with @basecolor & guard */
}
.loud > .open {
  color: #e6e6e6;
  /* redo with @basecolor */
}
.loud > .open:hover,
.loud > .open:focus {
  color: #bfbfbf;
}
.pale {
  color: #4d4d4d;
}
.skew {
  letter-spacing: 0.03em;
  font-style: italic;
  font-family: georgia, serif;
}
.text {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.open,
.load {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
.gist,
.dent {
  list-style: url(/images/list.png);
}
div.list {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
a {
  color: #4bb9be;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #007b8d;
}
.link {
  background: url('/icon-link/link.gif') no-repeat 0 0.4em;
}
.link.mail {
  background-image: url('/icon-link/mail.gif');
  background-position: 0 0.5em;
}
.load {
  background-position: 0 0.3em;
}
.rss {
  background-image: url('/icon-link/rss_small.gif');
}
div.link {
  background: none;
}
div.link a.open {
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  margin-left: 0;
  display: inline-block;
  background: #4bb9be;
  color: #000;
  padding: 2px 5px;
}
div.link a.open:hover,
div.link a.open:focus {
  background: #007b8d;
  color: #fff;
}
.cb-layout2 .main p.norm a.open[href*='equam.ch'] {
  float: left;
  display: block;
  width: 40px;
  height: 41px;
  overflow: hidden;
  text-indent: -9999px;
  background: url(/images/equam.png) no-repeat 0 0;
  margin-top: -12px;
  margin-right: 10px;
}
div.fold {
  margin-top: -1px;
  margin-bottom: 0;
}
div.fold div.body {
  margin-top: 0;
  margin-bottom: 0;
}
div.fold div.ctrl {
  position: absolute;
  top: 0;
  right: 1.85185185%;
}
div.fold div.ctrl a {
  display: block;
  width: 18px;
  height: 25px;
  background: url(/images/fold.svg) no-repeat 0 50%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
div.fold div.ctrl a.less {
  background: url(/images/fold-close.svg) no-repeat 0 50%;
}
div.fold div.more {
  float: left;
  width: 100%;
  padding-bottom: 30px;
}
.horz td,
.horz th {
  padding: 4px 1px;
}
.grow {
  text-transform: none !important;
}
/* MOOD CONTAINER */
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#edit #expo {
  height: 345px;
}
/* IMAGE CONTAINER */
#slides {
  overflow: hidden;
  position: relative;
  z-index: 1;
  max-width: 100% !important;
  max-height: 10000px !important;
}
#slides .slide {
  width: 100%;
}
#slides img {
  display: block;
  width: 100%;
  max-width: 100% !important;
  max-height: 10000px !important;
}
/* MOOD NAVIGATION */
#expo div.link {
  display: none;
}
.unit.form form {
  float: left;
  width: 100%;
}
.unit.form .part.word.tile {
  margin-top: 10px;
  margin-bottom: 10px;
  width: calc(50% - (100%/904*34));
}
@media (max-width: 764px) {
  .unit.form .part.word.tile {
    width: 96.23893805%;
  }
}
@media (max-width: 479px) {
  .unit.form .part.word.tile {
    width: 84%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
  font-size: 16px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 20px;
    padding-top: -2.5px;
    padding-bottom: -2.5px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: rgba(75, 185, 190, 0.3);
  min-height: 20px;
  padding: 5px 10px;
  border: unset;
  appearance: none;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: red;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: -2.5px;
    padding-bottom: -2.5px;
  }
}
.unit.form .part.tick.tile fieldset {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 100%;
}
.unit.form .part.tick.tile fieldset > .name {
  width: auto;
  padding-bottom: 0;
}
.unit.form .part.tick.tile fieldset > .ctrl {
  width: auto;
}
.unit.form .part.tick.tile fieldset > .ctrl > div {
  padding: 0;
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 25px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: -2.5px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'Open Sans', helvetica, sans-serif;
  font-size: 15px;
  line-height: 1.66666667;
  background: rgba(75, 185, 190, 0.3);
  height: 20px;
  border: 1px solid #000;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: red;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  width: 100%;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form .submit {
  float: right;
  margin-top: var(--spacePart, 10px);
  margin-bottom: var(--spacePart, 10px);
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.14166667em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #40abb0 #32878a #32878a #40abb0;
  border-radius: 2px;
  background-color: #4bb9be;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(43, 116, 120, 0.75);
  line-height: 2.2em;
}
.unit.form .submit:hover,
.unit.form .submit:focus {
  border-color: #40abb0 #32878a #32878a #40abb0;
  background-color: #56bdc2;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.unit.form .submit:active {
  outline: none;
  border-color: #40abb0 #32878a #32878a #40abb0;
  background-color: #39999d;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #000;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: red;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.14166667em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #40abb0 #32878a #32878a #40abb0;
  border-radius: 2px;
  background-color: #4bb9be;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(43, 116, 120, 0.75);
  line-height: 2.2em;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  border-color: #40abb0 #32878a #32878a #40abb0;
  background-color: #56bdc2;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.two-step-verification-container a:active {
  outline: none;
  border-color: #40abb0 #32878a #32878a #40abb0;
  background-color: #39999d;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
/*** mobile-navi-multilevel-right-left.less 2014-11-03 ***/
.navi,
#find,
.dock {
  display: none;
}
#mobile-navi {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 210;
  overflow: scroll;
  background: #fff;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  border-right: 1px solid #e1e2e2;
}
#mobileservices {
  float: left;
  display: block;
  width: 100%;
}
#mobileservices .meta {
  display: block;
  padding: 6px 15px 4px;
  color: #000;
  border-bottom: 1px solid #e1e2e2;
  font-size: 14px;
}
.togglenavigation {
  position: absolute;
  top: 14px;
  display: block;
  width: 24px;
  height: 12px;
  z-index: 210;
  cursor: pointer;
  color: #000 !important;
  -webkit-tap-highlight-color: transparent;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  -webkit-transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
}
.togglenavigation:active > span {
  background: #000000 !important;
}
.togglenavigation > span {
  left: 0;
  top: 50%;
  position: absolute;
  display: block;
  height: 2px;
  width: 24px;
  margin-top: -1px;
  background: currentColor;
}
.togglenavigation > .tline-1 {
  margin-top: -6px;
}
.togglenavigation > .tline-4 {
  margin-top: 4px;
}
.cb-toggle-target-active .togglenavigation > .tline-1,
.cb-toggle-target-active .togglenavigation > .tline-4 {
  opacity: 0;
}
.cb-toggle-target-active .togglenavigation > .tline-2,
.cb-toggle-target-active .togglenavigation > .tline-3 {
  -webkit-transition: -webkit-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  -o-transition: -o-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  -moz-transition: -moz-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  transition: transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
}
.cb-toggle-target-active .togglenavigation > .tline-2 {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.cb-toggle-target-active .togglenavigation > .tline-3 {
  opacity: 1;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
body.cb-toggle-target-active #mobile-navi {
  -o-transition: all 0.218s ease-out;
  -moz-transition: all 0.218s ease-out;
  -webkit-transition: all 0.218s ease-out;
  transition: all 0.218s ease-out;
}
body.cb-toggle-target-active .togglenavigation {
  -o-transition: all 0.2s ease-out;
  -moz-transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
  -webkit-transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
  transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
}
/* RIGHT */
/* LEFT */
#mobile-navi {
  left: -100%;
}
.togglenavigation {
  left: 8%;
}
body.cb-toggle-target-active #mobile-navi {
  left: 0;
}
#mobile-navi .navi {
  float: left;
  display: block;
  width: 100%;
  margin-top: 40px;
}
#mobile-navi .navi .item {
  width: 100%;
  float: left;
  position: relative;
  border-bottom: 1px solid #000;
  background: #4bb9be;
}
#mobile-navi .navi .item.init {
  border-top: 1px solid #000;
}
#mobile-navi .navi .item.cb-toggle-target-active {
  border: none;
}
#mobile-navi .navi .item .menu {
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  color: #000;
  padding: 16px 15px 14px;
  font-size: 16px;
}
#mobile-navi .navi .item .menu.path {
  color: #fff;
}
#mobile-navi .navi.sub2 {
  max-height: 0;
  overflow: hidden;
}
#mobile-navi .navi.sub2 .item .menu {
  font-size: 14px;
  padding-left: 30px;
}
#mobile-navi .navi.sub3 {
  max-height: 0;
  overflow: hidden;
}
#mobile-navi .navi.sub3 .item .menu {
  font-size: 13px;
  padding-left: 45px;
}
#mobile-navi div.navi > .item.cb-toggle-target-active > div.navi {
  max-height: 1000px;
  -o-transition: all 2s;
  -moz-transition: all 2s;
  -webkit-transition: all 2s;
  transition: all 2s;
}
#mobile-navi .cb-toggle {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  width: 37px;
  height: 37px;
  background: #ffffff;
}
#mobile-navi .cb-toggle:hover,
#mobile-navi .cb-toggle:focus {
  cursor: pointer;
}
#mobile-navi .cb-toggle.cb-toggle-active {
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
#mobile-navi div.sub2 .cb-toggle {
  height: 34px;
}
#mobile-navi .navi > .cb-toggle,
#mobile-navi .navi .item-empty > .cb-toggle {
  display: none;
}
/******* layout-small.less 2013-1-16 *******/
#navigation,
.service_custcontact,
#home {
  display: none;
}
#head {
  width: 84%;
  margin-left: 8%;
  margin-right: 8%;
  margin-top: 15px;
}
div.fold div.ctrl {
  right: 8%;
}
div.fold h2 {
  padding-right: 20px;
}
#header {
  border-bottom: 1px solid #000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 221;
  background: #fff;
}
#multimood {
  margin-top: 41px;
}
#content {
  padding-bottom: 40px;
}
#wrapper {
  font-size: 14px;
}
h1 {
  font-size: 22px;
}
.cb-layout2 h1 {
  color: #000;
}
h2 {
  font-size: 22px;
}
div.fold h2 {
  font-size: 14px;
}
div.grid h2 {
  font-size: 14px;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 8%;
  margin-left: 8%;
}
.area .part,
.area > .grid table {
  margin-right: 8%;
  margin-left: 8%;
  width: 84%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 84%;
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
h1,
h2 {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
/******* module-statistic.less 2013-1-16 *******/
.cb-statistics .chartclient-annotation-table {
  font-size: 12px;
  font-size: 1.2rem;
}
.cb-statistic-annotation li {
  list-style: none;
}
.cb-statistics .ctrl {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.cb-statistics .ctrl input {
  margin-right: 5px;
}
.cb-statistics .ctrl label {
  margin-right: 15px;
}
.cb-statistics .ctrl a {
  float: right;
}
#root .goog-custom-button-inner-box table,
#root .chartclient-annotation-table,
#root .annotatedtimelinetable {
  margin: 0;
}
#root #scrollingListTd {
  padding: 5px 0 5px 4px;
}
#root div.cb-statistics table.horz thead th {
  border-style: none none solid;
  border-color: #555;
  background-color: transparent;
}
#root div.cb-statistics table.horz tbody td {
  padding: 2px 1px;
  border: solid #ddd;
  border-width: 1px 0 0;
}
#root div.cb-statistics table.horz .odd td {
  background-color: #f9f9f9;
}
.cb-statistics .cb-visits {
  text-align: right;
}
/******* development.less 2013-1-16 *******/
/*.unit {
  box-shadow: 0 0 0 1px red inset;
}
 .part {
   box-shadow: 0 0 0 1px blue inset;
}*/
/*# sourceMappingURL=./screen-small.css.map */